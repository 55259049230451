/* eslint-disable consistent-return */
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useProject } from '../config/ProjectContext';
import GraphqlService from '../service/graphqlService';
import hasToValidateRole from '../utils/hasToValidateRole';

const useGetDevicesNotAllowed = (devices) => {
  const { project: { code, role } } = useProject();

  const getDevicesPermissionsByProject = async () => GraphqlService.getDevicesPermissionsByProject(code);

  const { data: devicePermissions, isLoading, isFetching } = useQuery(
    ['devicesPermissions', code],
    getDevicesPermissionsByProject,
    {
      enabled: !!code && hasToValidateRole(role) && devices.length > 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const areAllowed = useMemo(() => {
    if (!hasToValidateRole(role) || !devices || devices.length === 0) {
      return true;
    }

    if (isLoading || isFetching) {
      return false; // While loading, assume false to prevent errors
    }

    const devicesArray = Array.isArray(devices) ? devices : [devices];

    // return an array with the devices that are not allowed and were in the devices list
    return devicesArray.filter((device) => !devicePermissions?.includes(device));
  }, [role, isLoading, isFetching, devicePermissions, devices]);

  return { areAllowed, isLoading, isFetching };
};

export default useGetDevicesNotAllowed;
