/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import GraphqlService from '../../../../service/graphqlService';
import PageBanner from '../../../../components/utils/PageBanner';
import { useSnackbar } from '../../../../providers/SnackbarContext';
import YesNoDialog from '../../../../components/utils/YesNoDialog';

const useStyles = makeStyles((theme) => ({
  separate: {
    padding: 24,
  },
  table: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  selectProject: {
    width: '100%',
    maxWidth: 450,
  },
  background: {
    backgroundColor: theme.palette.background.typography,
  },
  button: {
    float: 'right',
    margin: '0 24px 24px 0',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: 16,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    borderRadius: 12,
  },
}));

export default function FineGrainedPermissions() {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [newDevice, setNewDevice] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const openSnackBar = useSnackbar();
  const [yesNoDialogOpen, setYesNoDialogOpen] = useState(false);
  const [yesNoDialogText, setYesNoDialogText] = useState('');
  const [deviceToDelete, setDeviceToDelete] = useState(null);
  const [searchDevice, setSearchDevice] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);

  const getProjects = async () => GraphqlService.getProjects();

  const {
    data: projectsData,
  } = useQuery(['getProjects'], () => getProjects(), {
    cacheTime: 1000,
  });

  const { data: devices, refetch } = useQuery(
    ['devicesPermissions', selectedBrand],
    () => GraphqlService.getDevicesPermissionsByProject(selectedBrand?.code),
    { enabled: !!selectedBrand },
  );

  const createDeviceMutation = useMutation(
    (device) => GraphqlService.createDevicePermission(selectedBrand?.code, device),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['devicesPermissions', selectedBrand?.code]);
        setNewDevice('');
        openSnackBar('Device added successfully', 'success');
      },
    },
  );

  const deleteDeviceMutation = useMutation(
    (device) => GraphqlService.deleteDevicePermission(selectedBrand?.code, device),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['devicesPermissions', selectedBrand?.code]);
        openSnackBar('Device deleted successfully', 'success');
      },
    },
  );

  const deleteAllDevicesMutation = useMutation(
    () => GraphqlService.deleteAllDevicesPermissions(selectedBrand?.code),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['devicesPermissions', selectedBrand?.code]);
      },
    },
  );

  const handleCreateDevice = () => {
    createDeviceMutation.mutate(newDevice, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  const handleDeleteDevice = (device) => {
    setDeviceToDelete(device);
    setYesNoDialogText(`Are you sure you want to delete the device ${device}?`);
    setYesNoDialogOpen(true);
  };

  const handleDeleteAllDevices = () => {
    deleteAllDevicesMutation.mutate();
  };

  const handleYesNoDialogAction = () => {
    if (deviceToDelete) {
      deleteDeviceMutation.mutate(deviceToDelete, {
        onSuccess: () => {
          refetch();
        },
      });
      setDeviceToDelete(null);
    } else {
      selectedDevices.forEach((device) => {
        deleteDeviceMutation.mutate(device, {
          onSuccess: () => {
            refetch();
          },
        });
      });
      setSelectedDevices([]);
    }
    setYesNoDialogOpen(false);
  };

  const handleSearchDevice = () => {
    refetch();
  };

  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
  };

  const handleSelectAllClick = () => {
    if (devices?.length === selectedDevices.length) {
      setSelectedDevices([]);
    } else {
      setSelectedDevices(devices);
    }
  };

  const handleSelectDevice = (device) => {
    const selectedIndex = selectedDevices.indexOf(device);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDevices, device);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDevices.slice(1));
    } else if (selectedIndex === selectedDevices.length - 1) {
      newSelected = newSelected.concat(selectedDevices.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDevices.slice(0, selectedIndex),
        selectedDevices.slice(selectedIndex + 1),
      );
    }

    setSelectedDevices(newSelected);
  };

  const handleDeleteSelectedDevices = () => {
    setYesNoDialogText('Are you sure you want to delete the selected devices?');
    setYesNoDialogOpen(true);
  };

  const handleFileUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const devicesToUpload = json.map((row) => row[0]);
      devicesToUpload.forEach((device) => {
        createDeviceMutation.mutate(device, {
          onSuccess: () => {
            refetch();
          },
        });
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xls,.xlsx',
    onDrop: handleFileUpload,
  });

  return (
    <Box>
      <PageBanner title="FINE GRAINED PERMISSIONS" />
      <p style={{ marginLeft: '24px', marginTop: '24px' }}>
        Restrict Developer access to specific devices.
      </p>
      <p style={{ marginLeft: '24px' }}>
        Please, add a Device Serial Number to allow developers’ visibility or upload a list.
      </p>
      <Grid item xs={12} className={classes.separate}>
        <FormControl className={classes.selectProject}>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <TextField
              select
              variant="outlined"
              className={classes.selectProject}
              label="Select project"
              value={selectedBrand?.code || ''}
              onChange={(e) => {
                setSelectedBrand(
                  projectsData?.find((project) => project.code === e.target.value),
                );
              }}
              style={{ marginRight: '16px' }}
            >
              {projectsData
                ?.sort((a, b) => a.code - b.code)
                .map((project) => (
                  <MenuItem key={project.code} value={project.code}>
                    {`${project.code} - ${project.name}`}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
          <Box mt={2} display="flex">
            <TextField
              label="Device Serial Number"
              value={newDevice}
              onChange={(e) => setNewDevice(e.target.value)}
              variant="outlined"
              style={{ width: '100%' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateDevice}
              disabled={!newDevice || !selectedBrand}
              style={{ marginLeft: '8px', width: '230px' }}
              startIcon={<AddIcon />}
            >
              Add Device
            </Button>
          </Box>
          <Box mt={2} display="flex" alignItems="center">
            <div {...getRootProps()} style={{ width: '100%' }}>
              <input {...getInputProps()} />
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
                disabled={!selectedBrand}
              >
                Upload File (XLS/XLSX/CSV)
              </Button>
            </div>
          </Box>
          <Typography variant="caption" display="block" gutterBottom style={{ marginTop: '8px' }}>
            ✔️ Upload your serial number list in CSV/XLSX format.
          </Typography>
          <Typography variant="caption" display="block" gutterBottom style={{ marginTop: '8px' }}>
            ✔️ Include a single column with no header.
          </Typography>
        </FormControl>
        <TableContainer component={Paper} className={classes.table}>
          <Table aria-label="fine grained permissions table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedDevices.length > 0 && selectedDevices.length < devices?.length}
                    checked={devices?.length > 0 && selectedDevices.length === devices.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h3">DEVICE SERIAL NUMBERS</Typography>
                    <Box display="flex" alignItems="center">
                      {searchOpen && (
                        <TextField
                          label="Search Device"
                          value={searchDevice}
                          onChange={(e) => setSearchDevice(e.target.value)}
                          variant="outlined"
                          size="small"
                          style={{ marginRight: '8px' }}
                        />
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearchToggle}
                        startIcon={<FilterListIcon />}
                      >
                        {searchOpen ? 'Close Search' : 'Search'}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteSelectedDevices}
                        startIcon={<DeleteIcon />}
                        style={{ marginLeft: '8px', color: 'white' }}
                        disabled={selectedDevices.length === 0}
                      >
                        Delete Selected
                      </Button>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>

            </TableHead>
            <TableBody>
              {devices
                ?.filter((device) => device.includes(searchDevice))
                .map((device) => (
                  <TableRow key={device} selected={selectedDevices.indexOf(device) !== -1}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedDevices.indexOf(device) !== -1}
                        onChange={() => handleSelectDevice(device)}
                      />
                    </TableCell>
                    <TableCell>{device}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteDevice(device)}
                        startIcon={<DeleteIcon />}
                        style={{ color: 'white' }}
                      >
                        DELETE
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <YesNoDialog
        open={yesNoDialogOpen}
        setOpen={setYesNoDialogOpen}
        header="Confirm Deletion"
        body={yesNoDialogText}
        action={handleYesNoDialogAction}
        actionName="Yes"
        id="confirmationDeleteDevice"
      />
    </Box>
  );
}
