/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-trailing-spaces */
/* eslint-disable import/no-named-default */
/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {
  Box, Hidden, Typography, Grid,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import BatteryCharging30Icon from '@material-ui/icons/BatteryCharging30';
import TodayIcon from '@material-ui/icons/Today';
import InfoIcon from '@material-ui/icons/Info';
import StorageIcon from '@material-ui/icons/Storage';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HistoryIcon from '@material-ui/icons/History';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import SettingsCellIcon from '@material-ui/icons/SettingsCell';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PersonIcon from '@material-ui/icons/Person';
import DevicesIcon from '@material-ui/icons/Devices';
import MemoryIcon from '@material-ui/icons/Memory';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FolderIcon from '@material-ui/icons/Folder';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Battery60Icon from '@material-ui/icons/Battery60';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { default as logo } from '../assets/portal_logo.svg';
import LeftBarMainItem from './LeftBarMainItem';
import {
  SHOW_ASSETS_PERMISSION,
  SHOW_MACHINE_EVENTS_PERMISSION,
  SHOW_BRAND_CONSUMPTIONS_PERMISSION,
  SHOW_TELEMETRY_EVENTS_PERMISSION,
  SHOW_IOT_REPORTS_PERMISSION,
  SHOW_SUPPORT_PERMISSION,
  SHOW_DEPLOYMENTS_PERMISSION,
  SHOW_LIFE_CYCLE_EVENTS_PERMISSION,
  SHOW_SANDBOX_OBJECT_PERMISSION,
  SHOW_MESSAGE_SIMULATOR,
  SHOW_CLOUD_TO_DEVICE,
  SHOW_CERTIFICATE_QUOTA,
  SHOW_CERTIFICATE_STATUS,
  IS_ROOT,
  GET_BUNDLES_FOR_GIVEN_BRAND,
  SHOW_FINE_GRAINED_PERMISSIONS,
  SHOW_PLATFORM_HEALTH,
} from '../utils/constants';
import { config, target } from '../config/awsExports';
import { useProject } from '../config/ProjectContext';
import ExpandableMenuSection from './ExpandableMenuSection';
import { targetName } from '../utils/portalEnvs';

// eslint-disable-next-line import/no-dynamic-require
const { env } = config;

const drawerWidth = 280;

const leftBarDeviceTelemetryListBase = [
  {
    name: 'Device Activity', path: '/statistics/machineEvents', permission: SHOW_MACHINE_EVENTS_PERMISSION, icon: <PhonelinkSetupIcon />,
  },
  {
    name: 'Device Consumptions', path: '/statistics/brandConsumptions', permission: SHOW_BRAND_CONSUMPTIONS_PERMISSION, icon: <BatteryCharging30Icon />,
  },
  {
    name: 'Device Events', path: '/statistics/brandEvents', permission: SHOW_TELEMETRY_EVENTS_PERMISSION, icon: <TodayIcon />,
  },
  {
    name: 'Device Info', path: '/statistics/deviceInfo', permission: SHOW_TELEMETRY_EVENTS_PERMISSION, icon: <InfoIcon />,
  },
  {
    name: 'Device Settings', path: '/statistics/deviceSettings', permission: SHOW_TELEMETRY_EVENTS_PERMISSION, icon: <SettingsCellIcon />,
  },
  {
    name: 'Device Raw Data', path: '/statistics/deviceRawData', permission: SHOW_SANDBOX_OBJECT_PERMISSION, icon: <StorageIcon />,
  },
  {
    name: 'Deployment Events History', path: '/statistics/deploymentEventsHistory', permission: SHOW_LIFE_CYCLE_EVENTS_PERMISSION, icon: <HistoryIcon />,
  },
];

const leftBarAssetManagementList = [
  {
    name: 'Assets', path: '/assets', permission: SHOW_ASSETS_PERMISSION, icon: <ListAltIcon />,
  },
  {
    name: 'Bundles', path: '/bundles', permission: GET_BUNDLES_FOR_GIVEN_BRAND, icon: <FolderIcon />,
  },
  {
    name: 'Deployments', path: '/deployments', permission: SHOW_DEPLOYMENTS_PERMISSION, icon: <AssignmentIcon />,
  },
  {
    name: 'Waves', path: '/waves', permission: SHOW_DEPLOYMENTS_PERMISSION, icon: <AssignmentIcon />,
  },
];

const leftBarMessageSimulatorList = [
  {
    name: 'Cloud To Device', path: '/cloudToDevice', permission: SHOW_CLOUD_TO_DEVICE, icon: <CloudDownloadIcon />,
  },
];

if (target === 'DEV' || target === 'STAG') {
  leftBarMessageSimulatorList.push({
    name: 'Device To Cloud', path: '/deviceToCloud', permission: SHOW_CLOUD_TO_DEVICE, icon: <CloudUploadIcon />,
  });
}

const leftBarCertificateManagementList = [
  {
    name: 'Quota Management', path: '/quotaManagement', permission: SHOW_CERTIFICATE_QUOTA, icon: <Battery60Icon />,
  },
  {
    name: 'Certificate Status', path: '/certificateStatus', permission: SHOW_CERTIFICATE_STATUS, icon: <ReceiptIcon />,
  },
];

const leftBarADMIN = [
  {
    name: 'Cognito Users', path: '/listCognitoUsers', permission: IS_ROOT, icon: <RecentActorsIcon />,
  },
  {
    name: 'Notification Center', path: '/notifications', permission: IS_ROOT, icon: <RecentActorsIcon />,
  },
  {
    name: 'User Activity', path: '/userActivity', permission: IS_ROOT, icon: <DirectionsRunIcon />,
  },
  {
    name: 'KPIS', path: '/kpis', permission: IS_ROOT, icon: <TrendingUpIcon />,
  },
  {
    name: 'Fine Grained Permissions', path: '/fineGrainedPermissions', permission: SHOW_FINE_GRAINED_PERMISSIONS, icon: <MenuBookIcon />,
  },
  {
    name: 'Useful Links', path: '/portalInfo', permission: IS_ROOT, icon: <MenuBookIcon />,
  },
  {
    name: 'Configuration', path: '/portalConfiguration', permission: IS_ROOT, icon: <SettingsIcon />,
  },
  {
    name: 'Platform Health', path: '/platformHealth', permission: IS_ROOT, icon: <LocalHospitalIcon />,
  },
];

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.appBar + 1,
    '& .MuiDrawer-paperAnchorLeft': {
      left: 'auto',

      clipPath: 'inset(0px -15px 0px 0px)',
      height: 'calc(100% - 56px)',
    },
  },
  drawerPaper: {
    width: drawerWidth,

  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerContainer: {
    overflow: 'auto',
    height: '100%',
    background: 'white',
  },
  headingTitle: {
    fontSize: 16,
    paddingLeft: 24,

  },
  drawerItem: {
    minHeight: 48,
    width: 300,
    paddingLeft: 16,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,

    marginTop: 4,
    marginBottom: 4,
    borderRadius: 4,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.form,
      color: 'black',
    },
  },
  drawerItemSection: {
    minHeight: 48,
    width: 256,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,

    marginTop: 4,
    marginBottom: 4,
    borderRadius: 4,
    pointerEvents: 'none',
  },
  drawerItemIcon: {
    justifyContent: 'center',
  },
  drawerItemNOIcon: {
    minWidth: 24,
  },
  drawerItemText: {
    paddingLeft: '2px',
  },
  drawerItemSectionText: {
    '& .MuiTypography-root': {

      fontFamily: theme.typography.h5.fontFamily,

      color: theme.palette.primary.text,
    },
    paddingLeft: 24,
  },
  typography: {
    height: 14,
    width: 100,
    color: theme.palette.secondary.text,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: 12,
    letterSpacing: 0,
    paddingBottom: 24,
    paddingLeft: 16,
    alignItems: 'flex-end',
  },
  logo: {
    transform: 'scale(0.8)',
    opacity: 0.9,
  },
  rectangle: {
    height: 1,
    width: 250,
    borderBottom: '1px solid #CCCCCC',
    position: 'center',
    marginLeft: 15,
  },
  mainTitlePadding: {
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '8px',

  },
}));

export default function LeftBar({ open, setOpen }) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeSection, setActiveSection] = React.useState(null);

  const { project } = useProject();

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleSetActive = (sectionName) => {
    setActiveSection(sectionName);
  };

  const ENV_VERSION = `v ${env.version}`;

  // Hide the “device consumptions” screen for users with access to any Purina Project (all roles),
  // 23 - Purina - Arbuckle / Smart Litter Box Monitor
  // 35 - Purina - Sirius Black
  // 36 - Purina - Fishtix
  // 37 - Purina - Bowl
  // 38 - Purina - Franken Devices

  const leftBarDeviceTelemetryList = (project?.code === 23 || project?.code === 35 || project?.code === 36 || project?.code === 37 || project?.code === 38)
    ? leftBarDeviceTelemetryListBase.filter((item) => item.name !== 'Device Consumptions') : leftBarDeviceTelemetryListBase;

  const titleName = `Consumer IoT Portal (${targetName[target]})`;
  // Use the GTM ID from the environment variables

  return (
    <Box component="nav">

      <Hidden mdUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={open}
          onClose={handleDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader}>
            <Typography className={classes.headingTitle} variant="h2">
              {titleName}
            </Typography>
            <IconButton onClick={handleDrawer}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <div className={classes.drawerContainer}>

            <List>
              <ExpandableMenuSection
                name="Admin"
                permissions={[
                  IS_ROOT,
                  SHOW_FINE_GRAINED_PERMISSIONS,
                  SHOW_PLATFORM_HEALTH,
                ]}
                items={leftBarADMIN}
                icon={<PersonIcon />}
                isActive={activeSection === 'Admin'}
                onToggle={() => handleSetActive('Admin')}
              />

              <ExpandableMenuSection
                name="Device Telemetry"
                permissions={[SHOW_MACHINE_EVENTS_PERMISSION, SHOW_BRAND_CONSUMPTIONS_PERMISSION, SHOW_TELEMETRY_EVENTS_PERMISSION, SHOW_SANDBOX_OBJECT_PERMISSION]}
                items={leftBarDeviceTelemetryList}
                icon={<DevicesIcon />}
                isActive={activeSection === 'Device Telemetry'}
                onToggle={() => handleSetActive('Device Telemetry')}
              />

              {project?.code !== 5 ? null : (
                <LeftBarMainItem
                  name="Module Settings"
                  path="/statistics/moduleSettings"
                  permission={SHOW_TELEMETRY_EVENTS_PERMISSION}
                  icon={<SettingsIcon />}
                  closeDrawer={() => handleSetActive()}
                />
              )}

              <ExpandableMenuSection
                name="Asset Management"
                permissions={[SHOW_ASSETS_PERMISSION, SHOW_DEPLOYMENTS_PERMISSION, GET_BUNDLES_FOR_GIVEN_BRAND]}
                items={leftBarAssetManagementList}
                icon={<MemoryIcon />}
                isActive={activeSection === 'Asset Management'}
                onToggle={() => handleSetActive('Asset Management')}
              />

              <ExpandableMenuSection
                name="Message Simulator"
                permissions={[SHOW_MESSAGE_SIMULATOR]}
                items={leftBarMessageSimulatorList}
                icon={<MemoryIcon />}
                isActive={activeSection === 'Message Simulator'}
                onToggle={() => handleSetActive('Message Simulator')}
              />

              <ExpandableMenuSection
                name="Certificate Management"
                permissions={[SHOW_CERTIFICATE_QUOTA, SHOW_CERTIFICATE_STATUS]}
                items={leftBarCertificateManagementList}
                icon={<BatteryUnknownIcon />}
                isActive={activeSection === 'Certificate Management'}
                onToggle={() => handleSetActive('Certificate Management')}
              />

              <LeftBarMainItem
                name="IoT Reports"
                path="/reports"
                permission={SHOW_IOT_REPORTS_PERMISSION}
                icon={<InsertChartIcon />}
                closeDrawer={() => handleSetActive()}
              />

              <LeftBarMainItem
                name="Service Request"
                path="/support"
                permission={SHOW_SUPPORT_PERMISSION}
                icon={<ContactSupportIcon />}
                closeDrawer={() => handleSetActive()}
              />

            </List>
          </div>
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          elevation={5}
        >
          <div className={classes.drawerContainer}>

            <List id="leftBar">
              <Grid display="flex" justifyContent="center" alignItems="center" direction="row" container className={classes.mainTitlePadding}>
                <Grid item xs={2}>
                  <img src={logo} alt="Logo" className={classes.logo} />
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.headingTitle} variant="h2">
                    {titleName}
                  </Typography>
                </Grid>

              </Grid>
              <ExpandableMenuSection
                name="Admin"
                permissions={[
                  IS_ROOT,
                  SHOW_FINE_GRAINED_PERMISSIONS,
                  SHOW_PLATFORM_HEALTH,
                ]}
                items={leftBarADMIN}
                icon={<PersonIcon />}
                isActive={activeSection === 'Admin'}
                onToggle={() => handleSetActive('Admin')}
              />

              <ExpandableMenuSection
                name="Device Telemetry"
                permissions={[SHOW_MACHINE_EVENTS_PERMISSION, SHOW_BRAND_CONSUMPTIONS_PERMISSION, SHOW_TELEMETRY_EVENTS_PERMISSION, SHOW_SANDBOX_OBJECT_PERMISSION]}
                items={leftBarDeviceTelemetryList}
                icon={<DevicesIcon />}
                isActive={activeSection === 'Device Telemetry'}
                onToggle={() => handleSetActive('Device Telemetry')}
              />

              {project?.code !== 5 ? null : (
                <LeftBarMainItem
                  name="Module Settings"
                  path="/statistics/moduleSettings"
                  permission={SHOW_TELEMETRY_EVENTS_PERMISSION}
                  icon={<SettingsIcon />}
                  closeDrawer={() => handleSetActive()}
                />
              )}

              <ExpandableMenuSection
                name="Asset Management"
                permissions={[SHOW_ASSETS_PERMISSION, SHOW_DEPLOYMENTS_PERMISSION]}
                items={leftBarAssetManagementList}
                icon={<MemoryIcon />}
                isActive={activeSection === 'Asset Management'}
                onToggle={() => handleSetActive('Asset Management')}
              />

              <ExpandableMenuSection
                name="Message Simulator"
                permissions={[SHOW_MESSAGE_SIMULATOR]}
                items={leftBarMessageSimulatorList}
                icon={<MemoryIcon />}
                isActive={activeSection === 'Message Simulator'}
                onToggle={() => handleSetActive('Message Simulator')}
              />

              <ExpandableMenuSection
                name="Certificate Management"
                permissions={[SHOW_CERTIFICATE_QUOTA, SHOW_CERTIFICATE_STATUS]}
                items={leftBarCertificateManagementList}
                icon={<BatteryUnknownIcon />}
                isActive={activeSection === 'Certificate Management'}
                onToggle={() => handleSetActive('Certificate Management')}
              />

              <LeftBarMainItem
                name="IoT Reports"
                path="/reports"
                permission={SHOW_IOT_REPORTS_PERMISSION}
                icon={<InsertChartIcon />}
                closeDrawer={() => handleSetActive()}
              />

              <LeftBarMainItem
                name="Service Request"
                path="/support"
                permission={SHOW_SUPPORT_PERMISSION}
                icon={<ContactSupportIcon />}
                closeDrawer={() => handleSetActive()}
              />

            </List>
          </div>
          <div>
            <Typography className={classes.typography}>
              {ENV_VERSION}
            </Typography>
          </div>
        </Drawer>
      </Hidden>

    </Box>

  );
}
LeftBar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
