import {
  Box, Grid, makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import {
  React, useState, useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useProject } from '../../config/ProjectContext';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import Filters from '../../components/utils/Filters';
import BrandObjectTablePaginated from './MachineTelemetry/BrandObjectTablePaginated';
import useLifeCycleObjectPaginated from './DeploymentDetails/useLifeCycleObjectPaginated';
import PaginationComponent from '../../components/Pagination';
import { calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../utils/dateUtils';
import FineGrainedPermissionsInfo from '../Admin/PlatformHealth/FineGrainedPermissions/FineGrainedPermissionsInfo';
import useIsDeviceAllowed from '../../hooks/useIsDevicePermissionAllowed';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

export default function DeviceInfo() {
  const MAX_DAYS_BETWEEN_DATES = 90;

  const { project } = useProject();

  const [alertOpen, setAlertOpen] = useState(false);

  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState('');
  const [deviceSerial, setDeviceSerial] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-29, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-29, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('23:59'));

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [deviceSerialError, setDeviceSerialError] = useState('');

  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const [isValidDevice, setIsValidDevice] = useState(false);

  const { isAllowed } = useIsDeviceAllowed(deviceSerial);

  const [alertText, setAlertText] = useState('');

  const classes = useStyles();

  const { handleSubmit } = useForm();

  const {
    data, isLoading, error, refetch,
  } = useLifeCycleObjectPaginated(
    'machineassetsdetail',
    project,
    deviceSerial,
    JSON.stringify({}),
    selectedStartDate,
    selectedEndDate,
    selectedStartTime,
    selectedEndTime,
    currentPage,
    pageSize,
  );

  const deploymentData = data?.data;
  const pagination = data?.pagination;

  useEffect(() => {
    if (isAllowed) {
      setIsValidDevice(true);
    } else {
      setIsValidDevice(false);
    }
  }, [isAllowed]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handleSelectChange = (event) => {
    setPageSize(event.target.value);
  };

  function openAlert(header, text) {
    setAlertText(text);
    setAlertOpen(true);
  }

  const onSubmit = () => {
    if (!isValidDevice) {
      openAlert('Error', 'Device does not have the required permissions.');
      return null;
    }

    setDeviceSerialError(!deviceSerial);
    if (!deviceSerial) {
      return undefined;
    }
    setIsFirstLoading(false);
    refetch();
    return undefined;
  };

  return (
    <Box spacing={0}>
      <ProgressDialog open={isLoading && !isFirstLoading} header="Retrieving events history, please wait" />
      {(error || alertText) && <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header="Error" body={error?.message || alertText} />}
      <PageBanner title="DEPLOYMENT EVENTS HISTORY" />

      <FineGrainedPermissionsInfo
        open={false}
        onClose={() => { }}
        style={{ margin: 24 }}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: 0 }}
      >
        <Filters
          id="DeploymentEventsHistory"
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          submitting={false}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedStartTime={selectedStartTime}
          setSelectedStartTime={setSelectedStartTime}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedEndTime={selectedEndTime}
          setSelectedEndTime={setSelectedEndTime}
          greaterSmallerDateError={greaterSmallerDateError}
          setGreaterSmallerDateError={setGreaterSmallerDateError}
          moreThanMaxDaysDateError={moreThanMaxDaysDateError}
          setMoreThanMaxDaysDateError={setMoreThanMaxDaysDateError}
          datesOptional={false}
          filtersVisible={false}
          deviceSerialVisible
          deviceSerial={deviceSerial}
          setDeviceSerial={setDeviceSerial}
          deviceSerialError={deviceSerialError}
          maxDaysBetweenDates={MAX_DAYS_BETWEEN_DATES}
          subtitle="Enter Device Serial to view asset deployment events"
        />
      </form>
      <Grid item xs={12}>
        <BrandObjectTablePaginated
          dataList={deploymentData}
          loading={isLoading}
          dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
          dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
          object={`machineassetsdetail/${project.code}/${deviceSerial}`}
          params={JSON.stringify({})}
          id="DeploymentDetails"
        />
        <div className={classes.tableIcons}>

          <PaginationComponent
            pageSize={pageSize}
            handleSelectChange={handleSelectChange}
            pagination={pagination}
            handlePageChange={handlePageChange}
          />

        </div>
      </Grid>
    </Box>
  );
}
