/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tooltip,
  IconButton,
  Table,
  FormControlLabel,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { TableHead } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState, useMemo,
} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoIcon from '@material-ui/icons/Info';
import { Doughnut } from 'react-chartjs-2';
import moment from 'moment';
import SummaryIcon from '@material-ui/icons/Assessment';
import { useHistory } from 'react-router-dom'; // Assuming you're using react-router
import { useQuery } from '@tanstack/react-query';
import DeleteIcon from '@material-ui/icons/Delete';
import GraphqlService from '../../service/graphqlService';
import useGetDevicesNotAllowed from '../../hooks/useGetDevicesNotAllowed';
import {
  formatToPrintableDate,
} from '../../utils/dateUtils';

import {
  DELETE_DEPLOY_PERMISSION,
} from '../../utils/constants';
import Restricted from '../../authorization/Restricted';
import { useSnackbar } from '../../providers/SnackbarContext';
import { useProject } from '../../config/ProjectContext';
import JsonPopup from '../JsonPopup';
import LoadingAnimation from '../LoadingAnimation';

import DeploymentType from './DeploymentType';

const useStyles = makeStyles((theme) => ({
  rootDetail: {
    flexGrow: 1,
    background: '#E2E2E2',
    height: '100%',
  },
  statusBullet: {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '5px',
  },
  detailItem: {
    borderRadius: '8px 0px 0px 8px',
    background: 'white',
  },
  detailInfoItem: {
    padding: 10,
  },
  detailInsight: {
    background: 'white',
    borderRadius: '0px 8px 8px 0px',
    padding: 8,
  },
  infoSection: {
    textAlign: 'left',
    height: '100%',
    background: 'white',
    borderRadius: 8,
    padding: 12,
  },
  headerDetailTitle: {
    paddingBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  infoValue: {
    marginBottom: theme.spacing(1),
  },
  headerValue: {
    fontWeight: 'bold',
  },

  details: {
    padding: 0,
  },
  cardTitle: {
    fontSize: 16,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  cardContent: {
    backgroundColor: 'white',
    color: theme.palette.primary.text,
    flexGrow: 1,
  },
  detailsTitle: {
    fontSize: 14,
    paddingRight: '8px',
  },
  accordionContainer: {
    marginLeft: 24,
    marginRight: 24,
    borderRadius: '0px',
  },
  root: {
    padding: 0,
    backgroundColor: 'white',
    marginBottom: 4,
    marginTop: 4,
    borderRadius: '0px',
  },
  summaryRoot: {
    backgroundColor: 'white',
    borderRadius: '0px',
    margin: 0,
  },
  accordionContent: {
    margin: -12,
  },
  headingTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  mainDetailTitle: {
    padding: theme.spacing(2),
    fontWeight: 'bold',
  },
  headingTitleElements: {
    fontWeight: theme.typography.h6.fontWeight,
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.text,
  },
  headingDescription: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.secondary.text,
  },
  headingDate: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.text,
  },
  headingDeletedDate: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: 'red',
  },
  detailsText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.secondary.text,
  },
  iconsBox: {
    float: 'center',
    margin: 0,
  },
  icon: {
    color: theme.palette.primary.text,
  },
  block: {
    background: theme.palette.background.typography,

  },
  blockTitle: {
    fontSize: 16,
    fontWeight: 500,

  },
  key: {
    color: theme.palette.primary.subMain,
    whiteSpace: 'nowrap',
  },
  cardHeader: {
    padding: 10,
  },
  tableBodyDetailItem: {
    height: '100%',
    background: 'white',
  },
  tableHeadDetailItem: {
    background: '#F2F2F2',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  bodyValue: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  overallContainer: {
    background: '#E2E2E2',
    padding: 10,
  },
  overallRootContainer: {
    marginBottom: 32,
  },
  infoItems: {
    marginBottom: 6,
  },
  deployHeaderItem: {
    marginBottom: 16,
  },
  tableStatus: {
    margin: 8,
    borderRadius: 8,
  },
  insightContainer: {
    width: '100%',
    height: '100%',
    maxWidth: 350,
    maxHeight: 350,
    alignContent: 'center',
    alignItems: 'center',
  },
  detailSerialFilterStyle: {
    width: '100%',
    minWidth: 250,

  },
  detailStatusFilterStyle: {
    width: '100%',
    minWidth: 190,
    marginTop: 6,
    paddingRight: 12,
  },
  dotsIcons: {
    alignSelf: 'center',
  },
  developmentTypeIcons: {
    alignSelf: 'flex-start',
  },
  downloadCloud: {
    padding: 0,
  },
  globalDeployIcon: {
    marginTop: 4,
  },
  gridChartProgress: {
    marginTop: 16,
    marginLeft: 16,
  },
  jsonCopyButton: {
    backgroundColor: theme.palette.primary.button,
    color: theme.palette.common.white,
    padding: '8px 16px',
    borderRadius: 12,
    '& :disabled': {
      color: theme.palette.primary.light,
    },
  },
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 42,
  },
}));

export default function DeploymentAccordion({
  deployment, id, deleted, deleteDeploy, expandedProp,
}) {
  const parsedTarget = (() => {
    try {
      const parsed = JSON.parse(deployment.target);
      return Array.isArray(parsed) ? parsed : [parsed];
    } catch (e) {
      return [deployment.target];
    }
  })();

  const { areAllowed: devicesNotAllowed, isLoading: isLoadingDevicesAllowed } = useGetDevicesNotAllowed(parsedTarget);

  const history = useHistory();

  const classes = useStyles();

  const [overallStatus, setOverallStatus] = useState([]);

  const { project } = useProject();

  const [expanded, setExpanded] = useState(expandedProp || false);

  const openSnackBar = useSnackbar();

  const handleNavigate = (url) => {
    if (deployment?.deploymentID && deployment?.dateTime) {
      const unixDate = moment(deployment.dateTime).unix();
      const newUrl = history.createHref({ pathname: `${url}/${deployment.deploymentID}/${unixDate}` });
      window.open(newUrl, '_blank'); // Opens in a new tab
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      openSnackBar('Value copied successfully to the clipboad!', 'success');
    }).catch((err) => {
      openSnackBar('Failed to copy text to clipboard', err);
    });
  };

  const openAsset = (fileId) => {
    const url = `${window.location.origin}/#/assetDetail/${fileId}`;
    window.open(url, '_blank');
  };

  const statusColors = {
    Done: 'green',
    Error: 'red',
    Executing: 'orange',
  };

  async function searchDeploymentDetails({
    startDate, deploymentId, params,
  }) {
    const response = await GraphqlService.getLifeCycleObject(
      project.code, `machinecount/${project.code}/${deploymentId}`, JSON.stringify(params), '', startDate, undefined, undefined,
    );

    if (response) {
      const generalData = JSON.parse(response.data);
      return generalData;
    }
    return [];
  }

  // // Define a query function
  const fetchDeploymentDetails = async (params) => {
    if (!expanded) return null;

    return searchDeploymentDetails({
      startDate: moment(deployment.dateTime),
      deploymentId: deployment.deploymentID,
      params,
    });
  };

  const {
    data: dataDeployments,
    isFetching: isFetchingDataDeployments,
  } = useQuery({
    queryKey: ['searchDataDeployments', deployment.deploymentID, expanded],
    queryFn: () => fetchDeploymentDetails({ isError: false }),
    enabled: expanded,
    refetchOnWindowFocus: false,
    staleTime: 30000,
    select: (res) => {
      if (!res?.validData) return [];
      return res.validData.map((item) => ({
        machineCount: item.machineCount,
        status: item.status,
      }));
    },
  });

  const {
    data: dataErrorDeployments,
    error: errorFetchingDataErrorDeployments,
    isFetching: isFetchingDataErrorDeployments,
  } = useQuery({
    queryKey: ['searchErrorDeployment', deployment.deploymentID, expanded],
    queryFn: () => fetchDeploymentDetails({ isError: true }),
    enabled: expanded,
    refetchOnWindowFocus: false,
    staleTime: 30000,
    select: (res) => {
      if (!res?.validData) return [];
      return res.validData.map((item) => ({
        machineCount: item.machineCount,
        status: item.status,
      }));
    },
  });

  // this use effect sums the total number of errors, done and executing machines
  useEffect(() => {
    const done = dataDeployments?.filter((item) => item.status === 7)?.reduce((acc, item) => (acc + (item?.machineCount || 0)), 0) || 0;
    const executing = dataDeployments?.filter((item) => item.status >= 1 && item.status < 7)?.reduce((acc, item) => (acc + (item?.machineCount || 0)), 0) || 0;
    const error = dataErrorDeployments?.reduce((acc, item) => (acc + (item?.machineCount || 0)), 0) || 0;

    const totalDone = done + error + executing;
    const statusInfo = [
      {
        status: 'Done',
        count: done,
        percentage: done ? Math.round((done / totalDone) * 100) : 0,
      },
      {
        status: 'Error',
        count: error || 0,
        percentage: error ? Math.round((error / totalDone) * 100) : 0,
      },
      {
        status: 'Executing',
        count: executing,
        percentage: executing ? Math.round((executing / totalDone) * 100) : 0,
      },
      {
        status: 'Total',
        count: totalDone,
      },
    ];

    const chartData = {
      datasets: [
        {
          label: 'Deployment Status',
          data: [statusInfo[0].percentage, statusInfo[1].percentage, statusInfo[2].percentage],
          backgroundColor: ['green', 'red', 'orange'],
          hoverOffset: 3,
        },
      ],
    };

    setOverallStatus({
      statusInfo,
      chartData,
    });
  }, [dataDeployments, dataErrorDeployments]);

  const doughnutOptions = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    cutout: '70%',
    responsive: true,
  };

  const hasInsight = JSON.stringify(overallStatus?.chartData?.datasets[0]?.data) !== JSON.stringify([0, 0, 0]);

  const isLoading = isFetchingDataDeployments || isFetchingDataErrorDeployments;

  if (!deployment || !deployment.file || !statusColors) {
    return null;
  }

  return (
    <div className={classes.accordionContainer}>
      <Accordion className={classes.root} id={`deployment-accordion-detail-${id}`} expanded={expanded} TransitionProps={{ unmountOnExit: true }} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon id={`${id}btnExpand`} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.summaryRoot}
          classes={{ content: classes.accordionContent }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            display="flex-start"
          >
            <Grid item xs={2} sm={2} className={classes.dotsIcons}>
              <Grid container display="flex" direction="row" justifyContent="center">
                <Tooltip title="View Deployment Details" arrow>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation(); // Prevents accordion from expanding
                      handleNavigate('/statistics/deploymentDetails');
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="View Deployment Summary" arrow>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation(); // Prevents accordion from expanding
                      handleNavigate('/statistics/deploymentStatus');
                    }}
                  >
                    <SummaryIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

            </Grid>

            {/* Type */}
            <Grid item xs={2} sm={1}>
              <ListItem>
                <ListItemText
                  id={`${id}Type`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Type</Typography>
                  }
                  secondary={<Typography variant="body2" component="div">{deployment.file.type}</Typography>}
                />
              </ListItem>
            </Grid>

            {/* Version */}
            <Grid container xs={4} sm={2}>
              <ListItem>
                <ListItemText
                  id={`${id}Version`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Version</Typography>
                  }
                  secondary={<Typography component="div" variant="body2">{deployment.file.assetDescription ? `v.${JSON.parse(deployment.file.assetDescription)?.Version}` : 'Not available'}</Typography>}
                />
              </ListItem>
            </Grid>

            {/* dateTime */}
            <Grid item xs={4} sm={3}>
              <ListItem>
                <ListItemText
                  id={`${id}Date`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Date</Typography>
                  }
                  secondary={<Typography variant="body2">{formatToPrintableDate(deployment.dateTime)}</Typography>}
                />
              </ListItem>
            </Grid>

            {/* DeletedDate */}
            {deleted && (
              <Grid item xs={4} sm={2}>

                <ListItem>
                  <ListItemText
                    id={`${id}DeletedDate`}
                    primary={
                      <Typography variant="caption" color="textSecondary">Deleted Date</Typography>
                    }
                    secondary={<Typography variant="body2" component="div" className={classes.headingDeletedDate}>{formatToPrintableDate(deployment.deleteTime)}</Typography>}
                  />
                </ListItem>

              </Grid>
            )}

            {/* Development Types */}
            <Grid item xs={8} sm={2} className={classes.developmentTypeIcons}>

              <ListItem>
                <ListItemText
                  id={`${id}Development Type`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Deployment Type</Typography>
                  }
                  secondary={(
                    <DeploymentType
                      id={`deployment-${id}`}
                      deployment={deployment}
                    />
                  )}
                />
              </ListItem>

            </Grid>

            {/* Delete */}
            <Grid item xs={1} sm={1} className={classes.dotsIcons}>
              <Grid container display="flex" direction="row" justifyContent="center">

                {!deleted && (
                  <Restricted to={[DELETE_DEPLOY_PERMISSION]}>
                    <Grid container xs={2} sm={1} alignItems="center">

                      {devicesNotAllowed?.length > 0 ? (
                        <Tooltip title={`Devices not allowed: ${devicesNotAllowed.join(', ')}`} arrow>
                          <InfoIcon />
                        </Tooltip>
                      ) : (
                        <FormControlLabel
                          aria-label="Acknowledge"
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          className={classes.iconsBox}
                          control={(
                            <Tooltip title="Delete deployment" arrow>
                              <IconButton
                                size="small"
                                aria-label="delete"
                                id={`DeleteIcon-${id}`}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  deleteDeploy(deployment.deploymentID);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        />
                      )}
                    </Grid>
                  </Restricted>
                )}

              </Grid>

            </Grid>

          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {isLoading ? (
            <Grid container alignContent="center" justifyContent="center" className={classes.loadingContainer}>
              <LoadingAnimation title="Loading deployment status" />
            </Grid>
          ) : (

            <Grid container className={classes.rootDetail}>
              <Grid container className={classes.overallRootContainer}>
                <Grid container alignContent="center" xs={12} sm={12} lg={8} className={classes.overallContainer}>
                  {!overallStatus?.chartData || !overallStatus?.statusInfo
                    ? (

                      <Grid container alignContent="center" justifyContent="center">

                        <Grid>
                          <Typography>
                            LOADING DATA
                          </Typography>
                        </Grid>
                        <Grid className={classes.gridChartProgress}>
                          <CircularProgress size={60} />
                        </Grid>
                      </Grid>

                    ) : (

                      <Grid container>
                        {/* Table status */}
                        <Grid
                          container
                          xs={12}
                          sm={12}
                          lg={
                            hasInsight ? 8 : 12
                          }
                          className={classes.detailItem}
                        >

                          <Typography variant="subtitle1" className={classes.mainDetailTitle}>
                            Overall Deployment Status
                          </Typography>
                          <Grid container xs={12} sm={12}>
                            <TableContainer style={{ width: '100%', overflowX: 'auto', padding: '8px' }}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell align="right">Devices</TableCell>
                                    <TableCell align="right">Percentage</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {/* Done */}
                                  <TableRow key={overallStatus?.statusInfo[0].status} className={classes.tableRow}>

                                    <TableCell component="th" scope="row">
                                      {overallStatus?.statusInfo[0].status}
                                      {' '}
                                      <span
                                        className={classes.statusBullet}
                                        style={{ backgroundColor: statusColors.Done }}
                                      />
                                    </TableCell>

                                    <TableCell align="right">{overallStatus?.statusInfo[0].count}</TableCell>
                                    <TableCell align="right">{overallStatus?.statusInfo[0].percentage !== undefined ? `${overallStatus?.statusInfo[0].percentage}%` : ''}</TableCell>
                                  </TableRow>

                                  {/* Error */}
                                  <TableRow key={overallStatus?.statusInfo[1].status} className={classes.tableRow}>

                                    {/* Error */}
                                    <TableCell component="th" scope="row">
                                      {overallStatus?.statusInfo[1].status}
                                      {' '}
                                      <span
                                        className={classes.statusBullet}
                                        style={{ backgroundColor: statusColors.Error }}
                                      />
                                    </TableCell>

                                    <TableCell align="right">{overallStatus?.statusInfo[1].count}</TableCell>
                                    <TableCell align="right">{overallStatus?.statusInfo[1].percentage !== undefined ? `${overallStatus?.statusInfo[1].percentage}%` : ''}</TableCell>
                                  </TableRow>
                                  {/* Executing */}
                                  <TableRow key={overallStatus?.statusInfo[2].status} className={classes.tableRow}>

                                    {/* Executing */}
                                    <TableCell component="th" scope="row">
                                      In progress
                                      {' '}
                                      <span
                                        className={classes.statusBullet}
                                        style={{ backgroundColor: statusColors.Executing }}
                                      />

                                      <Tooltip title="All the assets that are in phases of request, validation, download, verification, confirmation or execution." aria-label="download">
                                        <InfoIcon className={classes.icon} />
                                      </Tooltip>
                                    </TableCell>

                                    <TableCell align="right">{overallStatus?.statusInfo[2].count}</TableCell>
                                    <TableCell align="right">{overallStatus?.statusInfo[2].percentage !== undefined ? `${overallStatus?.statusInfo[2].percentage}%` : ''}</TableCell>
                                  </TableRow>
                                  {/* Total */}
                                  <TableRow key={overallStatus?.statusInfo[3].status} className={classes.tableRow}>

                                    {/* Total */}
                                    <TableCell component="th" scope="row">
                                      Total
                                      {' '}
                                      <Tooltip title="Total number of devices that initiated the software update process." aria-label="download">
                                        <InfoIcon className={classes.icon} />
                                      </Tooltip>

                                    </TableCell>

                                    <TableCell align="right">{overallStatus?.statusInfo[3].count}</TableCell>
                                  </TableRow>

                                </TableBody>
                              </Table>
                            </TableContainer>

                          </Grid>
                        </Grid>

                        {/* insight */}
                        {hasInsight && (
                          <Grid container xs={12} sm={12} lg={4} justifyContent="center" className={classes.detailInsight} alignContent="center" alignItems="center">
                            <div className={classes.insightContainer}>
                              {overallStatus?.chartData && <Doughnut data={overallStatus?.chartData} options={doughnutOptions} />}
                            </div>

                          </Grid>
                        )}

                      </Grid>
                    )}
                </Grid>

                {/* Detail Grid */}
                <Grid item xs={12} sm={12} lg={4} className={classes.detailInfoItem}>
                  <Grid className={classes.infoSection}>
                    <Typography variant="subtitle1" className={classes.headerDetailTitle}>
                      Information
                    </Typography>

                    <Grid container display="flex" direction="row" flex="1" className={classes.infoItems}>

                      <Grid container display="flex" direction="column" flex="1" xs={11}>
                        <Typography variant="body2" className={classes.headerValue}>
                          Deploy ID
                        </Typography>
                        <Typography variant="body2" className={classes.bodyValue}>
                          {deployment.deploymentID}
                        </Typography>

                      </Grid>
                      <Grid container xs={1}>
                        <IconButton onClick={() => copyToClipboard(deployment.deploymentID)}>
                          <FileCopyIcon />
                        </IconButton>
                      </Grid>

                    </Grid>

                    <Grid container display="flex" direction="row" className={classes.infoItems}>

                      <Grid item display="flex" flex="1">
                        <Typography variant="body2" className={classes.headerValue}>
                          Actor
                        </Typography>
                        <Typography variant="body2" className={classes.bodyValue}>
                          {deployment.deployDescription?.userName || deployment.actor}
                        </Typography>

                      </Grid>

                      <IconButton onClick={() => copyToClipboard(deployment.deployDescription?.userName || deployment.actor)}>
                        <FileCopyIcon />
                      </IconButton>

                    </Grid>

                    {deployment.deployDescription?.userDescription && (
                      <div className={classes.infoItems}>
                        <Typography variant="body2" className={classes.headerValue}>
                          Comments
                        </Typography>
                        <Typography variant="body2" className={classes.infoValue}>
                          {deployment.deployDescription.userDescription}
                        </Typography>
                      </div>
                    )}

                    {deployment.targetRegex && (
                      <div className={classes.infoItems}>
                        <Typography variant="body2" className={classes.headerValue}>
                          Target Regex
                        </Typography>
                        <Typography variant="body2" className={classes.infoValue}>
                          {deployment.targetRegex}
                        </Typography>
                      </div>
                    )}

                    {deployment.targetMarket && (
                      <div className={classes.infoItems}>
                        <Typography variant="body2" className={classes.headerValue}>
                          Target Market
                        </Typography>
                        <Typography variant="body2" className={classes.infoValue}>
                          {deployment.targetMarket}
                        </Typography>
                      </div>
                    )}

                    {deployment.excludeMarket
                      && (
                        <div className={classes.infoItems}>
                          <Typography variant="body2" className={classes.headerValue}>
                            Exclude Market
                          </Typography>
                          <Typography variant="body2" className={classes.infoValue}>
                            {deployment.excludeMarket}
                          </Typography>
                        </div>
                      )}

                    {deployment.exclude && (
                      <div className={classes.infoItems}>
                        <Typography variant="body2" className={classes.headerValue}>
                          Exclude
                        </Typography>
                        <Typography variant="body2" className={classes.infoValue}>
                          {deployment.exclude}
                        </Typography>
                      </div>
                    )}

                    {deployment.file.fileID && (

                      <Grid container display="flex" direction="row" className={classes.infoItems}>

                        <Grid>
                          <Typography variant="body2" className={classes.headerValue}>
                            Asset
                          </Typography>
                          <Typography variant="body2" className={classes.bodyValue}>
                            Open the associated asset
                          </Typography>

                        </Grid>

                        <IconButton aria-label="openAsset" onClick={() => openAsset(deployment.file.fileID)}>
                          <OpenInNewIcon />
                        </IconButton>

                      </Grid>
                    )}

                    {/* copy all deployment object with json button aligned to right */}
                    <Box display="flex" justifyContent="flex-end">
                      <JsonPopup
                        title="Deployment JSON"
                        jsonObject={typeof deployment === 'object' && deployment !== null ? deployment : {}}
                      />
                    </Box>

                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          )}

        </AccordionDetails>
      </Accordion>
    </div>

  );
}
DeploymentAccordion.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  deployment: PropTypes.any,
  id: PropTypes.string,
  deleted: PropTypes.bool,
  deleteDeploy: PropTypes.func,
  expandedProp: PropTypes.bool,
};
DeploymentAccordion.defaultProps = {
  deployment: undefined,
  id: undefined,
  deleted: false,
  deleteDeploy: undefined,
  expandedProp: false,
};
